import {
  type ErrorResponse,
  isRouteErrorResponse,
  Link,
  useParams,
  useRouteError,
} from "@remix-run/react";
import { getErrorMessage } from "~/utils/misc";

type StatusHandler = (info: {
  error: ErrorResponse;
  params: Record<string, string | undefined>;
}) => JSX.Element | null;

export function GeneralErrorBoundary({
  defaultStatusHandler = ({ error }) => {
    if (error.status === 404) {
      return (
        <section className="bg-white dark:bg-slate-900">
          <div className="container mx-auto flex min-h-screen items-center px-6 pb-12">
            <div>
              <p className="text-sm font-medium text-blue-500 dark:text-blue-400">
                404
              </p>
              <h1 className="mt-3 text-2xl font-semibold text-slate-800 dark:text-white md:text-3xl">
                Page not found
              </h1>
              {/* <p className="mt-4 text-slate-500 dark:text-slate-400"></p> */}

              <div className="mt-6 flex items-center gap-x-3">
                <Link
                  to="/dashboard"
                  className="w-1/2 shrink-0 rounded-lg bg-blue-500 px-5 py-2 text-sm tracking-wide text-white transition-colors duration-200 hover:bg-blue-600 dark:bg-blue-600 dark:hover:bg-blue-500 sm:w-auto"
                >
                  Back to Dashboard
                </Link>
              </div>
            </div>
          </div>
        </section>
      );
    }
    return (
      <p>
        {error.status} {error.data}
      </p>
    );
  },
  statusHandlers,
  unexpectedErrorHandler = (error) => <p>{getErrorMessage(error)}</p>,
}: {
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Record<number, StatusHandler>;
  unexpectedErrorHandler?: (error: unknown) => JSX.Element | null;
}) {
  const error = useRouteError();
  const params = useParams();

  if (typeof document !== "undefined") {
    console.error(error);
  }

  return (
    <div className="text-h2 container flex items-center justify-center p-20">
      {isRouteErrorResponse(error)
        ? (statusHandlers?.[error.status] ?? defaultStatusHandler)({
            error,
            params,
          })
        : unexpectedErrorHandler(error)}
    </div>
  );
}
